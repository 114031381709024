import React from "react";
import '../styles/semantic.min.css';
import {Button, Container, Divider, Grid, Header, Icon, Segment} from "semantic-ui-react";
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import PageHeader from "../components/utils/PageHeader";
import layout from "../templates/layout.module.css";
import MobileTabletContainer from "../container/MobileTabletContainer";
import DesktopContainer from "../container/DesktopContainer";
import styles from "../components/application/application.module.css";
import {graphql, StaticQuery} from "gatsby";

export default () => <LayoutContainer>
    <SEO title={'Anträge'}/>
    <MobileTabletContainer>
        <MainNavigation/>
        <Container fluid style={{marginBottom: '3em'}}>
            <PageHeader title={'Anträge'}/>
            <Segment className={styles.wrapper} raised>
                <Items/>
            </Segment>
        </Container>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer>
        <div className={layout.pageWrapper}>
            <MainNavigation/>
            <Container>
                <PageHeader title={'Anträge'}/>
                <Segment className={styles.wrapper} raised>
                    <Items/>
                </Segment>

            </Container>
            <Footer/>
        </div>
    </DesktopContainer>

</LayoutContainer>

function Items() {
    return <StaticQuery query={applicationQuery}
                 render={
                     data => {
                         const Applications = data.allMarkdownRemark.edges.map(i =>
                             <>
                                 <Header as='h3' className={styles.header}>{i.node.frontmatter.title}</Header>
                                 <Grid className={styles.grid} stackable>
                                     <Grid.Column width={"4"}>
                                         <Button
                                             icon
                                             labelPosition='left'
                                             target="_blank"
                                             className={styles.applicationButton}
                                             href={i.node.frontmatter.file.publicURL}>
                                             <Icon name='download'/>
                                             Antrag herunterladen
                                         </Button></Grid.Column>
                                     <Grid.Column width={"12"}>
                                         <>
                                             {
                                                 getAttachements(i.node.frontmatter.attachments)
                                             }
                                         </>
                                     </Grid.Column>
                                 </Grid>
                                 <Divider/>
                             </>
                         );
                         return (<>{Applications}</>)
                     }
                 }
    />;
}


function getAttachements(attachments) {
    const ass = attachments.map(j=>
        <Button icon labelPosition='left' href={j.file.publicURL} target="_blank" className={styles.attachementButton}>
            <Icon name='file' />
            {j.name}
        </Button>
    );
    return <>{ass}</>;
}

const applicationQuery = graphql`
query applicationQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "application" }}
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
        id
            frontmatter {
                title
                file {
                  id
                  publicURL
                }
                attachments {
                    name
                    file {
                        id
                        publicURL
                    }
                }
            }
        }
      }
    }
}

`;